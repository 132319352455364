// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global getAnimalPrescriptions, PhLSession, init, getCustomerInfo, getPrescriptionLineInfo,
 addToBasket,updateApproval2, getNonCurrentAnimalPrescriptions, removeFromBasket, getBasket,
 prepareOrder , doLogout, touchPhl, printMyMedDjur */
import { defineStore } from 'pinia';
import { sluggify } from '#root/shared/utils/types/string-utils';
import {
  roomTempCodes,
  chilledTempCodes,
  ecom2Loaded,
} from '../utils/prescription-utils';
// Currently there is a import cycle between cart and prescription store so this eslint ignore is needed
// TODO: Refactor to remove import cycle
// eslint-disable-next-line import/no-cycle
import { useCartStore } from './cart';

const handleEcom2Response = (response, callback, errorCallback) => {
  if (response.ok) {
    callback(response);
  } else {
    console.error(`Ecom2 error from: `, response.reason);
    if (errorCallback) {
      errorCallback(response.reason);
    }
  }
};

const getLicenseText = (license, numPackagesPerWithdrawal) =>
  `${license.preparatNamn} ${license.lakemedelsForm} ${license.styrka}, ${numPackagesPerWithdrawal}x${license.antal}x${license.mangd} ${license.enhetMangd}`;

const mapPrescription = (receipt, i18n) => ({
  amountLeft: receipt.resterandeAntalUttag,
  articleDescription: receipt.licens
    ? i18n.t('prescription.license', {
        license: getLicenseText(
          receipt.licensInformation,
          receipt.mangdperuttag
        ),
      })
    : receipt.artikelbenamning,
  brandSlug: receipt.produktbeskrivning
    ? sluggify(receipt.produktbeskrivning)
    : 'recept-produkt',
  details: {
    animalInfo: {
      animalIdentity: receipt.djuridentitetTyp,
      animalName: receipt.djuridentitet,
      animalType: receipt.djurslagKlartext,
      purpose: receipt.behandlingsandamal,
    },
    drugInfo: {
      atcCode: receipt.atcKod,
      deferredPeriod:
        (receipt.karens1 || receipt.karens2) &&
        `${receipt.karens1} ${receipt.karens2}`,
      dosageInfoPart1: receipt.doseringstext1,
      dosageInfoPart2: receipt.doseringstext2,
      packageInformation: receipt.forpackningsinnehall,
      substances: receipt.substans?.length ? receipt.substans.join(', ') : '',
    },
    refillInfo: {
      expeditionInterval:
        receipt.intervall && `${receipt.intervall} ${receipt.intervallenhet}`,
      firstWithdrawalLatest: receipt.forstaUttagFore,
      lastWithdrawal: receipt.senasteUttagdatum,
      numberOfWithdrawalsLeft: receipt.resterandeAntalUttag,
      quantityPerWithdrawal: receipt.mangdperuttag,
      quantityUnit: receipt.mangdenhet,
      totalNumberOfWithdrawals: receipt.forskrivetAntalUttag,
    },
    vetInfo: {
      issuedDate: new Date(receipt.ordinationstidpunkt),
      name: `${receipt.forskrivarefternamn} ${receipt.forskrivarfornamn}`,
      typeOfPrescriber: receipt.forskrivarkategori,
      workplaceAddress: receipt.arbetsplatsOrt,
      workplaceName: receipt.arbetsplatsNamn,
      // eslint-disable-next-line
      workplacePhone: receipt.arbetsplatsNamn?.includes('Firstvet')
        ? '010-206 70 66' // Number to customer support
        : receipt.forskrivarenstelefonnummer1
          ? receipt.forskrivarenstelefonnummer1
          : '-',
    },
  },
  dispatchable: receipt.expedierbar,
  expirationDate: new Date(receipt.sistaGiltighetsdag),
  fassUrl: `https://www.fass.se/LIF/product?userType=1&nplId=${receipt.nplId}`,
  hasExpeditionInterval: !!receipt.intervall,
  invalidDispatchText: receipt.ejexpedierbarText,
  itemNumber: receipt.varunr,
  license: receipt.licens,
  nplId: receipt.nplId,
  numberOfPackages: receipt.antalForpackningar,
  packageInformation: receipt.forpackningsinnehall,
  prescriptionId: parseInt(receipt.index, 10),
  price: 0,
  productId: parseInt(receipt.nplId, 10),
  saleStop: receipt.forsaljningsstoppad,
});

const mapBasketItem = (basketItem, i18n) => ({
  brandSlug: basketItem.produktbeskrivning
    ? sluggify(basketItem.produktbeskrivning)
    : 'recept-produkt',
  description: basketItem.license
    ? i18n.t('prescription.license', {
        license: licenbasketItem.articleDescription,
      })
    : basketItem.articleDescription,
  itemNumber: basketItem.varuNummer,
  prescriptionId: parseInt(basketItem.receptId, 10),
  price: parseFloat(basketItem.betalasavkund),
  quantity: basketItem.antalForpackningar,
  quantityPerWithdrawal: basketItem.forskrivetAntalPerUttag
    ? basketItem.forskrivetAntalPerUttag
    : 0,
  storageTempCode: basketItem?.deliveryInfo?.embeddedInfo?.storageTempCode,
});

export const usePrescriptionStore = defineStore('prescription', {
  state: () => ({
    basketId: null,
    basketItems: null,
    enableBasketUpdates: true,
    consent: true,
    customer: null,
    idleInterval: null,
    idleTimer: 0,
    isLoggedInToEcom2: null,
    isPlacingEcom2Order: false,
    loadingPastPrescriptions: false,
    pastPrescriptions: [],
    prescriptions: [],
    isLoggingInAndFetchingPrescriptions: false,
  }),
  getters: {
    basketAmount: (state) =>
      state.basketItems?.reduce(
        (totalPrice, item) => totalPrice + +item.price,
        0
      ) || 0,
    basketContainsChilledTempItems: (state) =>
      state.basketItems?.some((item) =>
        chilledTempCodes.includes(item.storageTempCode)
      ),
    basketContainsRoomTempItems: (state) =>
      state.basketItems?.some((item) =>
        roomTempCodes.includes(item.storageTempCode)
      ),

    basketItemCount: (state) =>
      state?.basketItems?.reduce((totalQuantity, item) => {
        const prescription = state.prescriptions.find(
          (thisPrescription) =>
            thisPrescription.prescriptionId === item.prescriptionId
        );

        if (!prescription) {
          return totalQuantity;
        }

        return totalQuantity + item.quantity / prescription.numberOfPackages;
      }, 0) || 0,
    getItemInBasket: (state) => (itemId) => {
      if (state.basketItems?.length) {
        return state.basketItems.find((item) => item.prescriptionId === itemId);
      }
      return null;
    },
  },
  actions: {
    setBasketId(basketId) {
      this.basketId = basketId;
    },
    setBasketItems(basket) {
      this.basketItems = basket;
    },
    setEnableBasketUpdates(payload) {
      this.enableBasketUpdates = payload;
    },
    setConsent(payload) {
      this.consent = payload;
    },
    setCustomer(customer) {
      this.customer = customer;
    },
    setDeliveryInfoOnPrescription({ prescriptionId, deliveryInfo }) {
      const prescriptionIndex = this.prescriptions.findIndex(
        ({ prescriptionId: thisPrescriptionId }) =>
          thisPrescriptionId === prescriptionId
      );
      if (prescriptionIndex >= 0) {
        this.prescriptions[prescriptionIndex].deliveryInfo = deliveryInfo;
      }
    },
    setIsLoggedInToEcom2(payload) {
      this.isLoggedInToEcom2 = payload;
    },
    setIsPlacingEcom2Order(payload) {
      this.isPlacingEcom2Order = payload;
    },
    setLoadingPastPrescriptions(payload) {
      this.loadingPastPrescriptions = payload;
    },
    setPastPrescriptions(pastPrescriptions) {
      this.pastPrescriptions = pastPrescriptions;
    },
    setPrescriptions(prescriptions) {
      this.prescriptions = prescriptions;
    },
    setPriceOnPrescription({ prescriptionId, price, sellableInfo }) {
      const prescriptionIndex = this.prescriptions.findIndex(
        ({ prescriptionId: thisPrescriptionId }) =>
          thisPrescriptionId === prescriptionId
      );
      if (prescriptionIndex >= 0) {
        const prescription = this.prescriptions[prescriptionIndex];
        this.prescriptions[prescriptionIndex].sellable = sellableInfo.sellable;
        this.prescriptions[prescriptionIndex].notSellableText =
          sellableInfo.notSellableText;
        // NOTE: setting price to always 0 on licensed products
        // another option could be avoid fetching product price for licensed products
        // but we may need the delivery info that comes with that
        if (prescription.license) {
          this.prescriptions[prescriptionIndex].price = 0;
        } else {
          this.prescriptions[prescriptionIndex].price = price;
        }
      }
    },

    clearBasket() {
      this.basketItems?.forEach((basketItem) => {
        const { prescriptionId } = basketItem;
        this.removeFromBasket({ prescriptionId });
      });
      this.setBasketItems(null);
    },
    addToBasket({ prescriptionId, quantity }) {
      const { isUpdatingEcom2Basket, createEcom2Basket } = useCartStore();
      if (this.enableBasketUpdates && !isUpdatingEcom2Basket) {
        return new Promise((resolve) => {
          const isUnder16 = false; // TODO update once we know the BankID user
          addToBasket(
            0,
            prescriptionId,
            0,
            quantity,
            isUnder16,
            ({ basket }) => {
              const nuxtApp = useNuxtApp();
              const basketId = String(basket[0]?.Id);
              this.setBasketId(basketId);
              this.setBasketItems(
                basket.map((basketItem) =>
                  mapBasketItem(basketItem, nuxtApp.$i18n)
                )
              );
              createEcom2Basket({ basketId }).then(() => {
                resolve(basketId);
              });
            }
          );
        });
      }
      return Promise.resolve();
    },
    approvePermissions() {
      handleEcom2Response(updateApproval2(0, true, true, true), (response) => {
        this.setConsent(response.ok);
        if (response.ok) {
          this.retrievePrescriptions();
        }
      });
    },

    getBasket() {
      if (!Object.keys(this.prescriptions).length) this.retrievePrescriptions();

      getBasket(0, ({ basket }) => {
        const nuxtApp = useNuxtApp();
        this.setBasketId(basket[0]?.Id);
        this.setBasketItems(
          basket.map((basketItem) => mapBasketItem(basketItem, nuxtApp.$i18n))
        );
      });
    },

    getCustomer() {
      if (!this.customer) {
        getCustomerInfo(0, (customer) => {
          if (customer.redNman) {
            const customerFirstName = customer.redNman.split(',')?.[1];
            const customerLastName = customer.redNman.split(',')?.[0];
            this.setCustomer({
              firstName: customerFirstName,
              lastName: customerLastName,
            });
          }
        });
      }
    },

    loginWithFVToken(token) {
      const prescriptionsToken = usePrescriptionsAuthTokenCookie();
      prescriptionsToken.value = token;
    },
    extendFVLogin10Minutes() {
      const prescriptionsToken = usePrescriptionsAuthTokenCookie();
      prescriptionsToken.expires = new Date(Date.now() + 10 * 60 * 1000);
    },

    logout(redirectPath) {
      const apiSEUrl = useRuntimeConfig().public.apiUrl[useRegionCode()];

      if (!redirectPath) {
        const localePath = useLocalePath();

        // eslint-disable-next-line no-param-reassign
        redirectPath = localePath({ name: 'shop' });
      }

      // check if there are any Rx products in the basket and remove them
      // from basket before logout
      this.basketItems?.forEach((basketItem) => {
        const { prescriptionId } = basketItem;
        this.removeFromBasket({ prescriptionId });
      });

      this.setPrescriptions([]);
      this.setPastPrescriptions([]);

      const prescriptionsToken = usePrescriptionsAuthTokenCookie();
      prescriptionsToken.value = null;
      prescriptionsToken.expires = 'Thu, 01 Jan 1970 00:00:00 GMT';

      const idsAuthToken = useIdsAuthTokenCookie();
      idsAuthToken.value = null;
      idsAuthToken.expires = 'Thu, 01 Jan 1970 00:00:00 GMT';

      doLogout(`${apiSEUrl}/rest/ecom2-logout?redirectPath=${redirectPath}`);

      // This should be done by the ecom2 script in `doLogout()` but it doesn't work. So we do it here until they fix it.
      sessionStorage.removeItem('PhLSession');
    },

    placeOrderForPrescription({ klarnaOrder, onPrescriptionConfirmed }) {
      this.setIsPlacingEcom2Order(true);
      const {
        given_name: givenName,
        family_name: familyName,
        street_address: streetAddress,
        city,
        postal_code: postalCode,
        phone,
        email,
      } = klarnaOrder.billing_address;

      const mappedOrder = {
        address: streetAddress,
        city,
        eMail: email,
        lines: this.basketItems.map((basketItem) => ({
          Id: 0,
          prescriptionId: basketItem.prescriptionId,
          qty: basketItem.quantity,
        })),
        mobileNo: phone,
        name: `${familyName}, ${givenName}`,
        phoneNo: phone,
        postCode: postalCode,
      };

      if (mappedOrder.lines.length === 0) {
        // This should never happen, but if it does, we want to know about it
        // The status in BigCommerce will be "Manually verification required"
        useNuxtApp().$sentryCaptureException(
          new Error(
            `🚨🚨🚨 ORDER ${klarnaOrder.orderId} NEEDS MANUALLY HANDLING because order lines are empty`
          )
        );
      }

      handleEcom2Response(
        prepareOrder(mappedOrder, false, false),
        (order) =>
          useNuxtApp()
            .$apiFetch(`/rest/prescription/confirm`, {
              method: 'POST',
              body: {
                bigCommerceOrderId: klarnaOrder.orderId,
                ecom2OrderId: order.orderNo,
              },
            })
            .then(() => {
              onPrescriptionConfirmed();
              this.setEnableBasketUpdates(true);
              this.setBasketItems([]);
              this.setIsPlacingEcom2Order(false);
            })
            .catch((error) => {
              this.setIsPlacingEcom2Order(false);
              useNuxtApp().$sentryCaptureException(
                new Error(
                  `🚨🚨🚨 ORDER ${klarnaOrder.orderId} NEEDS MANUALLY HANDLING because confirming prescription order failed: ${error.message}`
                )
              );
              return error;
            }),
        (error) => {
          this.setIsPlacingEcom2Order(false);
          useNuxtApp().$sentryCaptureException(
            new Error(
              `🚨🚨🚨 ORDER ${klarnaOrder.orderId} NEEDS MANUALLY HANDLING because ecom order failed: ${error.message}`
            )
          );
          return error;
        }
      );
    },

    removeFromBasket({ prescriptionId }) {
      const { isUpdatingEcom2Basket, deleteEcom2Basket } = useCartStore();
      if (this.enableBasketUpdates && !isUpdatingEcom2Basket) {
        return new Promise((resolve) => {
          handleEcom2Response(
            removeFromBasket(0, prescriptionId),
            ({ basket }) => {
              const nuxtApp = useNuxtApp();
              this.setBasketItems(
                basket.map((basketItem) =>
                  mapBasketItem(basketItem, nuxtApp.$i18n)
                )
              );
              if (this.basketItemCount === 0) {
                deleteEcom2Basket().then(() => {
                  resolve();
                });
              } else {
                resolve();
              }
            },
            (error) => {
              console.error(error);
            }
          );
        });
      }
      return Promise.resolve();
    },

    retrievePastPrescriptions() {
      this.setLoadingPastPrescriptions(true);
      getNonCurrentAnimalPrescriptions(0, (prescriptions) => {
        if (prescriptions?.recept) {
          const nuxtApp = useNuxtApp();
          this.setPastPrescriptions(
            prescriptions.recept.map((receipt) =>
              mapPrescription(receipt, nuxtApp.$i18n)
            )
          );
        } else {
          this.setPastPrescriptions([]);
        }
        this.setLoadingPastPrescriptions(false);
      });
    },

    retrievePrescriptions() {
      return new Promise((resolve) => {
        // The init and getAnimalPrescriptions functions are declared in the external ecom2.js file

        const onSessionExists = () => {
          handleEcom2Response(
            getAnimalPrescriptions(0),
            (prescriptions) => {
              const nuxtApp = useNuxtApp();
              this.setConsent(prescriptions.ok);
              this.setPrescriptions(
                prescriptions.recept.map((receipt) =>
                  mapPrescription(receipt, nuxtApp.$i18n)
                )
              );
              prescriptions.recept.forEach((receipt) => {
                this.retrievePriceInformation(receipt.index);
              });
              resolve();
            },
            () => {
              this.setConsent(false);
              resolve();
            }
          );
        };
        if (hasGToken()) {
          this.setIsLoggedInToEcom2(true);
          // TODO: use touchPhl to check if session is still valid
          onSessionExists();
        } else {
          handleEcom2Response(init(), () => {
            this.getCustomer();
            this.setIsLoggedInToEcom2(true);
            onSessionExists();
          });
        }
      });
    },

    retrievePriceInformation(prescriptionId) {
      getPrescriptionLineInfo(0, prescriptionId, (priceInformation) => {
        handleEcom2Response(priceInformation, (successfulPriceInformation) => {
          this.setPriceOnPrescription({
            prescriptionId,
            price: successfulPriceInformation.rader[0].kundkostnad,
            sellableInfo: {
              sellable: successfulPriceInformation.rader[0].saljbar,
              notSellableText:
                successfulPriceInformation.rader[0].ejSaljbarText,
            },
          });
          this.setDeliveryInfoOnPrescription({
            prescriptionId,
            deliveryInfo: successfulPriceInformation.rader[0].embeddedInfo,
          });
        });
      });
    },

    showPDF() {
      handleEcom2Response(printMyMedDjur(0), (response) => {
        window.open(response.pdf);
      });
    },

    updateIsLoggedIn() {
      /**
       * We need this check because it is possible that the ecom2.js script hasn't
       * been loaded yet. But in case the user is logged in we know the script
       * was loaded earlier (see default.vue).
       */
      if (ecom2Loaded()) {
        touchPhl((response) => {
          this.setIsLoggedInToEcom2(response.ok === true);
        });
      }
    },
  },
});
