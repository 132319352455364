declare let touchPhl: any;

const roomTempCodes = [
  '13',
  '14',
  '17',
  '18',
  '19',
  '25',
  '27',
  '28',
  '30',
  '31',
  '5',
  '6',
  '7',
  '8',
  'C',
  'E',
  'R',
];
const chilledTempCodes = ['10', '11', '16', '34', '4', '9', 'G', 'S'];
const invalidTempCodes = [
  '0',
  '15',
  '20',
  '21',
  '23',
  '29',
  '32',
  '33',
  'D',
  'I',
  'J',
  'L',
  'N',
];

const hasGToken = () =>
  window.sessionStorage &&
  window.sessionStorage.getItem('g_Token') &&
  window.sessionStorage.getItem('g_Token') !== 'null';

const ecom2Loaded = () => typeof touchPhl !== 'undefined';

const loadEcom2 = () => {
  const jquery = 'jquery';
  const existingJqueryScript = document.getElementById(jquery);

  if (!existingJqueryScript) {
    const jqueryScript = document.createElement('script');
    jqueryScript.onload = () => {
      const ecom2Js = 'ecom2Js';
      const existingEcom2Script = document.getElementById(ecom2Js);

      if (!existingEcom2Script) {
        const ecom2Script = document.createElement('script');
        ecom2Script.onload = () => {
          window.dispatchEvent(new Event('ecom2Loaded'));
        };

        ecom2Script.src = '/ecom-assets/ecom2/js/PhleCom.min.js';
        ecom2Script.id = ecom2Js;
        document.body.appendChild(ecom2Script);
      }
    };

    jqueryScript.src = 'https://code.jquery.com/jquery-3.7.0.min.js';
    jqueryScript.id = jquery;
    document.body.appendChild(jqueryScript);
  }
};

const waitForEcom2 = (prescriptionsEnabled: boolean, requireGToken = false) => {
  if (
    !prescriptionsEnabled ||
    (!hasGToken() && !requireGToken) ||
    ecom2Loaded()
  ) {
    return Promise.resolve();
  }
  return new Promise<void>((resolve) => {
    window.addEventListener(
      'ecom2Loaded',
      () => {
        resolve();
      },
      { once: true }
    );
  });
};

export {
  roomTempCodes,
  chilledTempCodes,
  invalidTempCodes,
  hasGToken,
  ecom2Loaded,
  loadEcom2,
  waitForEcom2,
};
