import { defineStore } from 'pinia';
import type {
  Subscription,
  CartSubscriptionItem,
} from '#root/shared/types/subscription-types';
import gql from 'graphql-tag';
import { safeJsonParse } from '#root/shared/utils/types/string-utils';
import { type SubscriptionCancellationReason } from '#root/shared/types/graphql-types';
import { calculateSubscriptionDiscountPrice } from '#root/shared/utils/subscription-utils';
import { useSubscriptionsReceiptStore } from './subscriptionsReceipt';

export const useSubscriptionStore = defineStore('subscription', () => {
  const subscriptionReceiptStore = useSubscriptionsReceiptStore();

  const subscriptionProductsInStore = ref<CartSubscriptionItem[]>([]);
  const enabledFeaturesStore = useEnabledFeaturesStore();
  const authStore = useAuthStore();
  const subscriptionLocalStorageKey = 'subscriptionProducts';
  const subscription = ref<Subscription | null>(null);

  // Local storage accessors
  const getSubscriptionProductsFromLocalStorage =
    (): CartSubscriptionItem[] => {
      // Get existing subscription products from localStorage
      const subscriptionProductsJSON = localStorage.getItem(
        subscriptionLocalStorageKey
      );

      if (subscriptionProductsJSON !== null) {
        return safeJsonParse(subscriptionProductsJSON) || [];
      }

      return [];
    };

  const setSubscriptionProductsFromLocalStorage = (
    subscriptionProduct: CartSubscriptionItem[]
  ) => {
    const updatedSubscriptionProducts = JSON.stringify(subscriptionProduct);
    localStorage.setItem(
      subscriptionLocalStorageKey,
      updatedSubscriptionProducts
    );
  };

  const addSubscriptionProductToLocalStorage = (
    subscriptionProduct: CartSubscriptionItem
  ) => {
    // Add subscription product to array of subscriptions products in localStorage

    // Get existing subscription products from localStorage
    const existingSubscriptionProducts: CartSubscriptionItem[] =
      getSubscriptionProductsFromLocalStorage();

    const foundSubscriptionProduct = existingSubscriptionProducts.find(
      (prod) =>
        prod.productId === subscriptionProduct.productId &&
        prod.variantId === subscriptionProduct.variantId &&
        prod.frequencyInDays === subscriptionProduct.frequencyInDays
    );

    if (foundSubscriptionProduct) {
      return updateSubscriptionProductQuantity(
        subscriptionProduct.productId,
        subscriptionProduct.variantId,
        subscriptionProduct.frequencyInDays,
        subscriptionProduct.quantity
      );
    }

    // Add the new subscription product
    existingSubscriptionProducts.push(subscriptionProduct);

    // Stringify and update subscription products in localStorage
    setSubscriptionProductsFromLocalStorage(existingSubscriptionProducts);

    return existingSubscriptionProducts;
  };

  const updateSubscriptionProductQuantityToLocalStorage = async (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number,
    newQuantity: number
  ) => {
    // Update the quantity of a subscription product present in the array of subscriptions products in localStorage

    if (newQuantity === 0) {
      return removeSubscriptionProductFromLocalStorage(
        productId,
        variantId,
        frequencyInDays
      );
    }

    // Get existing subscription products from localStorage
    const existingSubscriptionProducts =
      getSubscriptionProductsFromLocalStorage();

    const subscriptionProductIndex = existingSubscriptionProducts.findIndex(
      (product) =>
        product.productId === productId &&
        product.variantId === variantId &&
        product.frequencyInDays === frequencyInDays
    );

    const currentSubscriptionProduct =
      existingSubscriptionProducts[subscriptionProductIndex];

    if (currentSubscriptionProduct) {
      currentSubscriptionProduct.quantity = newQuantity;

      const hasPreviousOrders = await loggedInUserHasPreviousSubscriptions();

      const updatedProduct = updateProductWithTotalPrices(
        currentSubscriptionProduct,
        hasPreviousOrders
      );
      existingSubscriptionProducts[subscriptionProductIndex] = updatedProduct;
    }

    // Stringify and update subscription products in localStorage
    setSubscriptionProductsFromLocalStorage(existingSubscriptionProducts);

    return existingSubscriptionProducts;
  };

  const removeSubscriptionProductFromLocalStorage = (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number
  ) => {
    // Removes a subscription product present in the array of subscriptions products in localStorage

    // Get existing subscription products from localStorage
    let existingSubscriptionProducts =
      getSubscriptionProductsFromLocalStorage();

    existingSubscriptionProducts = existingSubscriptionProducts.filter(
      (product) =>
        !(
          product.productId === productId &&
          product.variantId === variantId &&
          product.frequencyInDays === frequencyInDays
        )
    );

    // Stringify and update subscription products in localStorage
    setSubscriptionProductsFromLocalStorage(existingSubscriptionProducts);

    return existingSubscriptionProducts;
  };

  const loadSubscriptionProducts = async (): Promise<
    CartSubscriptionItem[]
  > => {
    if (typeof localStorage === 'undefined') {
      // Mock localStorage if not available
      subscriptionProductsInStore.value = [];
      return [];
    }

    // Get subscription products from local storage
    const parsedSubscriptionProducts =
      getSubscriptionProductsFromLocalStorage();

    const hasPreviousOrders = await loggedInUserHasPreviousSubscriptions();

    // Calculate price based on subscription discount
    const productsWithPrices = parsedSubscriptionProducts.map((product) =>
      updateProductWithTotalPrices(product, hasPreviousOrders)
    );

    // Set subscription products in store
    subscriptionProductsInStore.value = productsWithPrices;

    return productsWithPrices;
  };

  const subscriptionProducts = computed(() => {
    if (enabledFeaturesStore.isFeatureEnabled('subscriptions')) {
      return subscriptionProductsInStore.value;
    }
    return [];
  });

  const getSubscriptionProductInCart = async (
    productId: number,
    variantId: number
  ) => {
    await loadSubscriptionProducts();

    return subscriptionProducts.value.find(
      (product) =>
        product.productId === productId && product.variantId === variantId
    );
  };

  const addSubscriptionProductToCart = (product: CartSubscriptionItem) => {
    const searchedIndex = subscriptionProductsInStore.value.findIndex(
      (prod) =>
        prod.productId === product.productId &&
        prod.variantId === product.variantId &&
        prod.frequencyInDays === product.frequencyInDays
    );

    // This is a safety in case this function is called after a product update
    if (searchedIndex !== -1) return;

    subscriptionProductsInStore.value = [
      ...subscriptionProductsInStore.value,
      product,
    ];
  };

  const updateSubscriptionProductsInCart = (
    updatedSubscriptionProducts: CartSubscriptionItem[]
  ) => {
    subscriptionProductsInStore.value = updatedSubscriptionProducts;
  };

  const addSubscriptionProduct = async (
    product: Omit<CartSubscriptionItem, 'prices'>
  ) => {
    const hasPreviousOrders = await loggedInUserHasPreviousSubscriptions();
    const updatedProduct = updateProductWithTotalPrices(
      product,
      hasPreviousOrders
    );

    addSubscriptionProductToLocalStorage(updatedProduct);
    addSubscriptionProductToCart(updatedProduct);
  };

  const updateSubscriptionProductQuantity = async (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number,
    quantity: number
  ) => {
    const updatedSubscriptionProduct =
      await updateSubscriptionProductQuantityToLocalStorage(
        productId,
        variantId,
        frequencyInDays,
        quantity
      );

    return updateSubscriptionProductsInCart(updatedSubscriptionProduct);
  };
  const removeSubscriptionProduct = (
    productId: string | number,
    variantId: string | number,
    frequencyInDays: number
  ) =>
    updateSubscriptionProductsInCart(
      removeSubscriptionProductFromLocalStorage(
        productId,
        variantId,
        frequencyInDays
      )
    );

  // Using subscriptionProductsInStore to avoid showing potentially outdated data from localStorage after cart clear
  const subscriptionItemCount = computed(
    () =>
      subscriptionProductsInStore.value.reduce(
        (acc: number, curr: { quantity: number }) => acc + curr.quantity,
        0
      ) || 0
  );

  // Total base amount (before any discount is applied)
  const subscriptionBaseAmount = computed(
    () =>
      subscriptionProducts?.value.reduce(
        (total: number, product: CartSubscriptionItem) =>
          total + (product.prices?.basePrice.value ?? 0),
        0
      ) || 0
  );

  // Total after potential product campaign price is applied (not including subscription discount)
  const subscriptionSaleAmount = computed(
    () =>
      subscriptionProducts?.value.reduce(
        (total: number, product: CartSubscriptionItem) =>
          total +
          (product.prices.salePrice?.value ?? product.prices.basePrice.value),
        0
      ) || 0
  );

  // Total after potential product campaign price AND subscription discount is applied
  const subscriptionAmount = computed(
    () =>
      subscriptionProducts?.value.reduce(
        (total: number, product: CartSubscriptionItem) =>
          total + (product.prices?.price.value ?? 0),
        0
      ) || 0
  );

  // Negative value representing the normal product campaign discount (not including subscription discount)
  const subscriptionCampaignDiscountAmount = computed(
    () => subscriptionSaleAmount.value - subscriptionBaseAmount.value
  );

  const updateProductWithTotalPrices = (
    product: Omit<CartSubscriptionItem, 'prices'>,
    hasPreviousOrders: boolean | null = null
  ): CartSubscriptionItem => {
    const publicRuntimeConfig = useRuntimeConfig().public;

    const priceAfterCampaignAndSubscriptionDiscounts =
      calculateSubscriptionDiscountPrice({
        price: product.unitPrices.price.value,
        env: publicRuntimeConfig.isProd ? 'prod' : 'dev',
        shopBrand: publicRuntimeConfig.brand as ShopBrand,
        regionCode: useRegionCode(),
        productBrandId: product.brand?.entityId,
        isFirstOrder: hasPreviousOrders === null ? false : !hasPreviousOrders,
      }) * product.quantity;

    const prices = {
      ...product.unitPrices,
      basePrice: {
        ...product.unitPrices.basePrice,
        value: product.unitPrices.basePrice.value * product.quantity,
      },
      price: {
        ...product.unitPrices.price,
        value: priceAfterCampaignAndSubscriptionDiscounts,
      },
      salePrice: product.unitPrices.salePrice
        ? {
            ...product.unitPrices.salePrice,
            value: product.unitPrices.salePrice.value * product.quantity,
          }
        : null,
    };

    return { ...product, prices };
  };

  const clearSubscriptionProductsInStore = () => {
    subscriptionProductsInStore.value = [];
  };

  const clearCart = (checkoutId: string) => {
    // Save subscription products to show in the receipt
    subscriptionReceiptStore.receipts.push({
      checkoutId,
      products: getSubscriptionProductsFromLocalStorage(),
    });

    localStorage.setItem(subscriptionLocalStorageKey, JSON.stringify([]));
    clearSubscriptionProductsInStore();
  };

  const getSubscriptionDiscountAmount = async () => {
    const subscriptionPrice =
      subscriptionSaleAmount.value || subscriptionBaseAmount.value;

    const publicRuntimeConfig = useRuntimeConfig().public;

    const hasPreviousOrders = await loggedInUserHasPreviousSubscriptions();

    const subscriptionDiscountPrice = subscriptionProducts.value?.reduce(
      (totalSubscriptionDiscount, product) =>
        totalSubscriptionDiscount +
        calculateSubscriptionDiscountPrice({
          price: product.unitPrices.price.value * product.quantity,
          env: publicRuntimeConfig.isProd ? 'prod' : 'dev',
          shopBrand: publicRuntimeConfig.brand as ShopBrand,
          regionCode: useRegionCode(),
          productBrandId: product.brand?.entityId,
          isFirstOrder: hasPreviousOrders === null ? false : !hasPreviousOrders,
        }),
      0
    );

    return subscriptionPrice - subscriptionDiscountPrice;
  };

  const cancelSubscription = async (
    id: string,
    reason: SubscriptionCancellationReason
  ) => {
    try {
      const { $apollo } = useNuxtApp();

      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation CancelSubscription(
            $id: ID!
            $reason: SubscriptionCancellationReason!
          ) {
            cancelSubscription(id: $id, reason: $reason)
          }
        `,
        variables: {
          id,
          reason,
        },
      });

      // TODO: if the cancel fails will return false, handle return value and error
      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  const skipSubscription = async (
    subscriptionShipmentId: string,
    subscriptionId: string
  ) => {
    try {
      const { $apollo } = useNuxtApp();

      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation SkipSubscriptionShipment(
            $subscriptionShipmentId: ID!
            $subscriptionId: ID!
          ) {
            skipSubscriptionShipment(
              subscriptionShipmentId: $subscriptionShipmentId
              subscriptionId: $subscriptionId
            )
          }
        `,
        variables: {
          subscriptionShipmentId,
          subscriptionId,
        },
      });

      // TODO: if the skip fails will return false, handle return value and error
      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  const pauseSubscription = async (id: string) => {
    try {
      const { $apollo } = useNuxtApp();

      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation PauseSubscription($id: ID!) {
            pauseSubscription(id: $id) {
              id
              status
            }
          }
        `,
        variables: {
          id,
        },
      });

      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  const resumeSubscription = async (id: string) => {
    try {
      const { $apollo } = useNuxtApp();

      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation ResumeSubscription($id: ID!) {
            resumeSubscription(id: $id) {
              nextShipmentDate
              subscription {
                id
                status
              }
            }
          }
        `,
        variables: {
          id,
        },
      });

      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  const loggedInUserHasPreviousSubscriptions = async () => {
    if (!authStore.isLoggedIn) {
      return null;
    }

    const { $apollo } = useNuxtApp();

    try {
      const { data } = await $apollo().query({
        query: gql`
          query Subscriptions {
            subscriptions(includeDeleted: true) {
              edges {
                node {
                  entityId
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });

      return !!data.subscriptions?.edges?.length;
    } catch (error) {
      console.error('Failed to get subscriptions:', error);
    }

    return false;
  };

  return {
    subscriptionProducts,
    subscriptionItemCount,
    getSubscriptionProductInCart,
    addSubscriptionProduct,
    updateSubscriptionProductQuantity,
    removeSubscriptionProduct,
    subscriptionBaseAmount,
    subscriptionSaleAmount,
    subscriptionAmount,
    subscriptionCampaignDiscountAmount,
    getSubscriptionDiscountAmount,
    subscription,
    clearCart,
    loadSubscriptionProducts,
    cancelSubscription,
    pauseSubscription,
    skipSubscription,
    resumeSubscription,
    clearSubscriptionProductsInStore,
    loggedInUserHasPreviousSubscriptions,
  };
});
