<template>
  <div
    class="py-auto flex h-screen w-full flex-col items-center justify-center bg-background-extra-light"
  >
    <SeoMetaEditorial :title="message" :description="message" />
    <ClearCacheMeta />
    <div
      class="flex flex-col items-center justify-center rounded-full bg-supporting-light-4"
      style="height: 48vh; width: 48vh"
    >
      <div class="mb-2 text-5xl md:text-6xl">😿</div>
      <h1 class="mb-3 text-xl md:mb-6 md:text-3xl">
        {{ message }}
      </h1>
      <button
        class="text-base underline md:text-lg"
        @click="clearError({ redirect: homeUrl })"
      >
        {{
          $t('error.go_to_start_page').replace(
            'error.go_to_start_page',
            'Go to start page'
          )
        }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const localePath = useLocalePath();

const homeUrl = ref(localePath('/'));

definePageMeta({
  layout: 'empty',
});

const props = defineProps({
  error: {
    type: Object,
    default: () => ({
      clientMessage: '',
    }),
  },
});

const message = computed(() =>
  props.error?.statusCode === 404
    ? t('error.page_not_found').replace(
        'error.page_not_found',
        'Page not found'
      )
    : props.error?.clientMessage ||
      t('error.something_went_wrong').replace(
        'error.something_went_wrong',
        'Something went wrong'
      )
);
</script>
